import React, { useState, useEffect, Component, useRef } from 'react';
import logo from './ReciLogo.png';
import YouTube from 'react-youtube';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Header1 from './header1.js'
import SelectedReci from './ReciSelected.js'
import { ImgOverlay } from 'image-overlay-react'
import { Router, Routes, Route, Link, useParams } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import Serving_Size_Icon from './images/serving_size.png'
import Min_Cook_Icon from './images/min_cook.png'
import ReciLogo from './images/ReciLogo.png'
import Gem_Badge from './images/gem_badge.png'
import Shelf_Icon from './images/shelf_filled.png'
import User_Icon from './images/user.png'
import Website_Icon from './images/website.png'
import Cook_Icon from './images/cook.png'
import Calendar_Icon from './images/calendar.png'
import Share_Icon from './images/share.png'
import Quantity_Icon from './images/quantity.png'
import Units_Icon from './images/Units.png'
import Ingredient_Icon from './images/ingredient.png'
import Nutrition_Icon from './images/nutrition.png'
import Carbs_Icon from './images/carbs.png'
import Energy_Icon from './images/energy.png'
import Protein_Icon from './images/protein.png'
import Fats_Icon from './images/fats.png'
import Sugar_Icon from './images/sugar.png'
import Nutrition_Border from './images/Nutrient_border.png'
import Download_PDF from './images/PDF Download.png'
import Fridge_Icon from './images/fridge.png'
import Fridge_Green_Check from './images/in_fridge.png'

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookMessengerIcon,
  EmailIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TumblrIcon,
  TwitterIcon,
  WeiboIcon,
  WhatsappIcon,
} from "react-share";
import ReactPlayer from 'react-player'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Overlay from 'react-image-overlay'
import { connect, useDispatch, useSelector } from 'react-redux';
import ProfileImage from './images/profile.png'
import { useAuthState } from "react-firebase-hooks/auth";
import { insertRecipe, auth, db, logout, storage } from "./firebase";
import store from './_store/configurestore.js';

import { changepost } from './_actions/postActions'
import { changeprofile } from './_actions/profileActions'
import { query, collection, onSnapshot, where } from "firebase/firestore";
import { Card } from 'react-bootstrap'
import QRCode from 'react-qr-code'
import generatePDF from 'react-to-pdf';
import ReciSelectedPrint from './ReciSelectedPrint.js'
import { ReactMediaRecorder } from "react-media-recorder";


function ReciSelected(props) {
  const [user, loading, error] = useAuthState(auth);

  const [info, setInfo] = useState([]);
  const [Recis, setRecis] = useState([]);
  const [Fridge, setFridge] = useState([]);
  const [postreducer, setpostreducer] = useState("test");

  const params = useParams();

  console.log('xxxx params', params);

  const dispatch = useDispatch();

  const state = useSelector((state) => state);

  console.log(state)

  useEffect(() => {
    const state = store.getState();
    setpostreducer(state.postreducer)
  })

  const opts = {
    height: '600',
    width: '90%',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    },
  };

  //const {postreducer} = this.props;

  const divStyle = {
    paddingTop: '100px ',
    margin: 'auto',
    width: '80%',


  };

  // Create ref for PDF generation
  const targetRef = useRef();

  // Modify the PDF generation click handler
  const handlePDFDownload = () => {
    generatePDF(targetRef, {
      filename: `${Recis[0]?.data.Title}.pdf`,
      page: {
        // Optional: Add any specific page settings you need
        margin: 20,
        format: 'letter'
      }
    });
  };

  // useEffect(() => {
  //   db.collection(process.env.REACT_APP_COLLECTION_POSTS).where("myID", "==", params.id).limit(1).onSnapshot((snapshot) => {
  //     setRecis(
  //       snapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         data: doc.data(),
  //       }))
  //     );
  //   });
  //   console.log({ Recis });
  // }, []);

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_POSTS), where("myID", "==", params.id))
    onSnapshot(q, (querySnapshot) => {
      setRecis(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      })))
    })
  }, []);

  useEffect(() => {
    const q = query(collection(db, "fridge"), where("userID", "==", params.id))
    onSnapshot(q, (querySnapshot) => {
      setFridge(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      })))
    })
  }, []);

  return (
    <>
      {/* Hidden div for PDF generation */}
      <div ref={targetRef} style={{ position: 'absolute', left: '-9999px' }}>
        <ReciSelectedPrint data={Recis[0]?.data} />
      </div>

      <div className='web-container'>
    
        <div className='reci-detail'>

          {Recis?.map(({ id, data }) => (
            <Grid key={id}>
              {/* <Card className='shadow p-4'> */}
              <Row className="mx-0">

                <Col>
                  <p><b>{data.Cuisine}</b>: {data.Title}</p>
                  <p>

                  <b> Author: </b>
                      {/*<Link onClick={() => dispatch(changeprofile(data.user_id))} to={`/ProfileSelected/${data?.user_id ? data?.user_id : data?.userdoc_id}`}>{data.author ? data.author[0]?.Name : ""} </Link>*/}

                   {data.author.map((name, index) => <a key={name} href={data.author[index].profile_URL}>{data.author[index].Name}</a>)}

                  </p>

                  <p><img src={Serving_Size_Icon} alt="Serving Size" width="20" height="20" /> Serving Size: {data.serving_size} <img src={Min_Cook_Icon} alt="Min Cook" width="20" height="20" /> Min Cook: {data.min_cook} min</p>

    {/* <ReactMediaRecorder
      video
      render={({ status, startRecording, stopRecording, mediaBlobUrl }) => (
        <div>
          <p>{status}</p>
          <p>
          <button onClick={startRecording}>Start Recording</button>
          <button onClick={stopRecording}>Stop Recording</button>
          </p>
          <video src={mediaBlobUrl} controls autoPlay loop />
        </div>
      )}
    /> */}

                  <Row className="mx-0">
                    <ReactPlayer url={data.YouTube_URL} style={{ backgroundColor: '#000000' }} width="100%" controls="true" playing="true" />
                  </Row>
                </Col>

                <div className="mx-0 reci-tools">
                  <Link onClick={() => dispatch(changeprofile(data.user_id))} to={`/ProfileSelected/${data.userdoc_id}`}><Button as={Col} variant="primary"><img src={data.profile_pic ? data.profile_pic : ProfileImage} alt="User" width="30" height="30" /></Button></Link>
                  <Link to={`/SelectedReci/${id}`}><Button onClick={() => dispatch(changepost(data.post_id))} as={Col} variant="secondary" className="mx-2">
                    <img src={Cook_Icon} alt="Cook this Reci" width="35" height="35" />
                  </Button></Link>
                  {
                    data.Website_URL && <a href={data.Website_URL} target="_blank"><Button as={Col} variant="primary"><img src={Website_Icon} alt="Website" width="30" height="30" /></Button></a>
                  }
                  {/* <Button as={Col} variant="primary"><img src={Nutrition_Icon} alt="See Reci Nutrition Label (Sugars, Carbs, Energy, Fats, and Proteins)" width="35" height="35"/></Button> */}
                  {/* <a href={data.Website_URL? data.Website_URL : data.YouTube_URL}><Button as={Col} variant="primary"><img src={Website_Icon} alt="Website" width="30" height="30" /> </Button></a> */}
                  <Button onClick={handlePDFDownload}>
                    <img src={Download_PDF} alt="Download PDF" width="30" height="30" />
                  </Button>
                </div>
                <div className="mx-0 text-center">
                  <EmailShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><EmailIcon size={32} round={true} /></EmailShareButton>
                  <FacebookShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><FacebookMessengerIcon size={32} round={true} /></FacebookShareButton>
                  <LinkedinShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><LinkedinIcon size={32} round={true} /></LinkedinShareButton>
                  <PinterestShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} media={data.Website_URL ? data.Website_URL : data.YouTube_URL}><PinterestIcon size={32} round={true} /></PinterestShareButton>
                  <RedditShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><RedditIcon size={32} round={true} /></RedditShareButton>
                  <TumblrShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><TumblrIcon size={32} round={true} /></TumblrShareButton>
                  <TwitterShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><TwitterIcon size={32} round={true} /></TwitterShareButton>
                  <WhatsappShareButton as={Col} url={data.Website_URL ? data.Website_URL : data.YouTube_URL} ><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
                </div>
              </Row>

              <h4 className='mt-2'>Method</h4>
              <div className='mt-3' style={{ marginLeft: '5%', marginRight: '5%' }} >
                {data.Reci.Steps.map((item, index) =>
                  <p key={item}><b>Step {index + 1}</b> {item}</p>
                )}
              </div>

              <h4 className='mb-2'>{data.Reci.Ingredients.length} Ingredients </h4>
              {data.Reci.Ingredients.map((name, index) => {
                // Check if the ingredient exists in the fridge
                //const isInFridge = data.Ingredients[0] === data.Reci.Ingredients[index].Ingredient;
                return (
                  <p className='reci-detail__Ingredients' key={name}>
                    <img src={Ingredient_Icon} alt="Ingredient" width="30" height="30" style={{ marginBottom: '17px', marginRight: '3px' }} />
                    <b>Ingredient:</b> {data.Reci.Ingredients[index].Ingredient}
                    <img src={Units_Icon} alt="Amount" width="25" height="25" style={{ marginBottom: '17px', marginRight: '3px', marginLeft: '3px' }} />
                    <b>Amount:</b> {data.Reci.Ingredients[index].Amount} {data.Reci.Ingredients[index].Units}
                    {/*isInFridge  <img src={Fridge_Green_Check} alt="In Fridge" width="30" height="30" style={{ marginLeft: '5px' }} />} */} 
                    <div style={{ display: 'block', unicodeBidi: 'isolate', color: 'red', backgroundColor: 'red', height: '0.5px' }}>
                      <hr />
                    </div>
                  </p>
                );
              })}

              

              {/* <div style={{ border: '7px solid red', justifyContent: 'center', alignItems: 'center', left: '50%', display: 'none' }}>
                <b>Nutrition</b>
                <p><img src={Carbs_Icon} alt="Cook this Reci" width="35" height="35" /> Carbs: {data.Nutrition.Carbs.Amount} {data.Nutrition.Carbs.Units}</p>
                <p><img src={Energy_Icon} alt="Cook this Reci" width="35" height="35" /> Energy: {data.Nutrition.Energy.Amount} {data.Nutrition.Carbs.Units}</p>
                <p><img src={Fats_Icon} alt="Cook this Reci" width="35" height="35" /> Fats: {data.Nutrition.Fat.Amount} {data.Nutrition.Carbs.Units}</p>
                <p><img src={Protein_Icon} alt="Cook this Reci" width="35" height="35" /> Protein: {data.Nutrition.Protein.Amount} {data.Nutrition.Carbs.Units}</p>
                <p><img src={Sugar_Icon} alt="Cook this Reci" width="35" height="35" /> Sugar: {data.Nutrition.Sugars.Amount} {data.Nutrition.Carbs.Units}</p>
              </div> */}


              {/* </Card> */}
            </Grid>
          ))}
        </div>
      </div >
    </>
  );
}

const mapStateToProps = (state) => {
  const { postreducer } = state
  return {
    postreducer
  }
}

export default connect(mapStateToProps)(ReciSelected);
