import React from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const Checkout = () => {
  //const location = useLocation();
  //const { price } = location.state;

  const handleCheckout = async () => {
    try {
      const response = await axios.post('https://api.lulu.com/v1/print-jobs', {
        // Add necessary data for the print job
        // Example:
        title: 'Cookbook Title',
        price: 10,
        // Other required fields based on Lulu API documentation
      });
      console.log('Checkout successful:', response.data);
      // Handle successful checkout (e.g., redirect to confirmation page)
    } catch (error) {
      console.error('Error during checkout:', error);
      // Handle error (e.g., show error message)
    }
  };

  return (
    <div>
      <h1>Checkout</h1>
      <p>Total Price: </p>
      <button onClick={handleCheckout}>Confirm Purchase</button>
    </div>
  );
};

export default Checkout; 