import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db, storage } from "../firebase";
import uploadIcon from '../images/upload.png';
import ReciAIIcon from '../images/reci_ai_logo.png'
import { useEffect, useState } from "react";
import { Col, Form, InputGroup, ProgressBar } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { query, collection, getDocs, where } from "firebase/firestore";
import axios from 'axios';
import ReactPlayer from 'react-player'
import { ref, uploadBytesResumable, getDownloadURL, connectStorageEmulator } from "firebase/storage";
import Loader from "../Components/Loader"

const AIRecipeComponent = () => {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [userData, setUserData] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [percent, setPercent] = useState(0);
    const [inputField, setInputField] = useState('');
    const [errors, setErrors] = useState(false);
    const [file, setFile] = useState("");
    const [LoderVisible, setLoderVisible] = useState(false);
    const [url, setUrl] = useState('');
    const [videoInfo, setVideoInfo] = useState(null);
  

    useEffect(() => {
        fetchUserName();
    }, [])

    const fetchUserName = async () => {
        try {
            const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid));
            const doc = await getDocs(q);
            const data = doc.docs[0].data();
            setUserData({ ...data, id: doc.docs[0].id });
        } catch (err) {
            console.error(err);
        }
    };

    const inputsHandler = (e) => {
        e.preventDefault();
        setInputField(e.target.value);
        setErrors(false);
    }

    function handleChange(event) {
        setFile(event.target.files[0]);
        setErrors(false);
    }

    var isoString = Date();
    var isoRandomString = Math.floor(Math.random())

    const handleUpload = (e) => {
        e.preventDefault();
        if (!file) {
            setErrors({ ...errors, Videofile: 'Please upload video!' })
            return
        }
        setUploading(true);
        const storageRef = ref(storage, `/files/${file.name}/${isoString}_${isoRandomString}`)
        // progress can be paused and resumed. It also exposes progress updates.
        // Receives the storage reference and the file to upload.
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                // update progress
                setPercent(percent);
                setUploading(false);
            },
            (err) => console.log(err),
            () => {
                // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    console.log(url);
                    setInputField(url)
                    setUploading(false);
                    setErrors(false);
                });
            }
        );
    };

    const handleClear = () => {
        setFile(null);
        setInputField(null);
        setUploading(false);
        setPercent(0);
        setErrors(false);
    }

    const handleSubmit = async () => {
        if (inputField !== "") {
            setLoderVisible(true);
            let payload = { url: inputField, user_id: userData?.id }
            await axios.post('https://reci-ai.uc.r.appspot.com/process_video_reci', payload, {
                headers: {
                    Authorization: "zS1UyPMSrI7TzjMnZ45n3"
                }
            })
                .then(res => {
                    toast.success(res.message);
                    setLoderVisible(false);
                    if (res?.data?.Formatted_Recipe) {
                        navigate('/addrecipe', {
                            state: {
                                AIdata: res?.data?.Formatted_Recipe,
                                AIcooking: true,
                                AIurl: inputField
                            }
                        })
                    }
                })
                .catch(err => {
                    setLoderVisible(false);
                    toast.error("Try again, or use manual uploader")
                    console.log(err, "err");
                });
        } else {
            setErrors(true);
        }

    }

    return (
        <>
            {LoderVisible && (<Loader />)}
            <div className={`${user ? 'page-container' : 'web-container'}`}>
                <div className='form_row video_field'>
                    <div>
                        <h2>Upload your <span style={{ color:'red'}}>Reci</span> using <span style={{ color:'red'}}>Reci AI</span><img style={{height:55, width:50}} src={ReciAIIcon} /></h2>
                        <p>You should upload your recipe video, ensuring you provide clear voiceover instructions and include the list of ingredients and steps for best results.</p>
                    </div>

                    <div className="img_list">
                        <img src={uploadIcon} />
                    </div>
                    <div>
                        <Form.Group as={Col} md="12" className="mb-3 d-flex justify-content-center" controlId="validationCustom02">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    required
                                    type="file"
                                    placeholder="Upload Video File *"
                                    onChange={handleChange}
                                    accept="video/mp4,video/x-m4v,video/*"
                                />
                                <InputGroup.Text
                                    id="basic-addon2"
                                    className={`theme-bg ${uploading ? 'disabled' : ''}`}
                                    onClick={inputField ? handleClear : handleUpload}
                                    disabled={uploading}
                                >
                                    {inputField ? "clear" : "Upload Video"}
                                </InputGroup.Text>
                            </InputGroup>

                        </Form.Group>
                        <ProgressBar className="upload-progress" now={percent} label={`${percent}%`} animated striped />
                        {inputField &&
                            <ReactPlayer url={inputField} style={{ backgroundColor: '#000', margin: '20px 0px' }} width="100%" controls="true" />
                        }
                    </div>
                    {/* <Form.Group as={Col} md="12" className="mb-3" controlId="validationCustom02">
                        <Form.Control
                            type="text"
                            name="youtube_url"
                            value={inputField}
                            placeholder="Youtube URl *"
                            onChange={inputsHandler}
                        />
                        <div className="text-danger mt-2">
                            {errors ? "Please Enter Your video url" : ""}
                        </div>
                    </Form.Group> */}
                </div>
                <div className="text-danger mt-2">
                    {errors ? "Please upload Your video" : ""}
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <button className="theme-button-secondary " style={{ marginRight: '20px' }} onClick={() => navigate('/myRecipes')} >Back</button>
                    <button className="theme-button " onClick={handleSubmit}>Generate Reci</button>

                </div>
            </div>
        </>
    )
}

export default AIRecipeComponent;
