import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import MailIcon from '@mui/icons-material/Mail';
// import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Image, Button } from 'react-bootstrap'
import ProfileIcon from '../images/cookprofile.png'
import CookbookIcon from '../images/Catalog.png'
import RecipeIcon from '../images/batter.png'
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, db } from "../firebase";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import ProfileImage from '../images/profile.png'
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { query, collection, getDocs, where, increment, onSnapshot } from "firebase/firestore";


const drawerWidth = 240;
const NAVBAR_HEIGHT = '64px';

function Sidebar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [user, loading, error] = useAuthState(auth);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [info, setInfo] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [logged_user_id, setLoggedUserId] = useState("");
  const [Recis, setRecis] = useState([]);

  const location = useLocation();

  const allowedRoutes = ['userProfile', 'dashboard', 'products', 'myRecipes', 'addrecipe', 'airecipe', 'bookedEvents', 'paymentHistory'];

  const paramas = location.pathname.split('/');
  const shouldDisplayDrawer = allowedRoutes.includes(paramas[1]);
  console.log(paramas, "paramas", location.pathname)

  useEffect(() => {
    if (user === null) {
      setUserData(null);
    } else {
      fetchUserName();

    }
  }, [user, loading]);

  useEffect(() => {
    const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid))
    onSnapshot(q, (querySnapshot) => {
      setRecis(querySnapshot.docs.map(doc => ({
        id: doc.id,
        data: doc.data(),
      })))
    })
  }, []);


  const fetchUserName = async () => {
    try {
      const q = query(collection(db, process.env.REACT_APP_COLLECTION_USERS), where("uid", "==", user?.uid));
      const doc = await getDocs(q);

      if (!doc.empty) {
        const data = doc.docs[0].data();
        setUserData({ ...data, id: doc.docs[0].id });
      } else {
        setUserData(null);

      }

    } catch (err) {
      console.error(err);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log(user, "user")

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const logout = () => {
    props.setLogin(false);
    navigate('/login');
    signOut(auth);
    localStorage.clear();
    sessionStorage.clear();
  }

  const pageNavigation = (path) => {
    navigate(path);
    handleClose();
  }




  const drawer = (
    <div>
      <Toolbar
        sx={{ display: { xs: 'flex', sm: 'none' } }}
      >
        <Link to="/home" className="custom-link" onClick={handleDrawerClose}>
          Reci
        </Link>
      </Toolbar>
      <Divider />
      <List style={{ borderRight: '1px solid rgba(0, 0, 0, 0.12)', height: 'calc(100vh - 65px)' }}>

        <Divider sx={{ display: { xs: 'flex', sm: 'none' } }} />


        <ListItem key={'Recipes'} disablePadding>
          <Link to="/myRecipes" className="custom-link" onClick={handleDrawerClose}>
            <Image src={RecipeIcon} alt="Recipes"/> My Recis
          </Link>

        </ListItem>
        <ListItem key={'Cookbooks'} disablePadding>
          <Link to="/myCookbooks" className="custom-link" onClick={handleDrawerClose}>
            <Image src={CookbookIcon} alt="Cookbooks"/> Cookbooks
          </Link>
        </ListItem>

      </List>




    </div>
  );

  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;

  const navbarStyle = {
    height: '64px',
    minHeight: '64px',
  };

  return (
    <Box sx={{ display: 'flex' }} className="custom-sidebar">
      <CssBaseline />
      <AppBar
        position="fixed"
        // sx={{
        //   width: { sm: `calc(100% - ${drawerWidth}px)` },
        //   ml: { sm: `${drawerWidth}px` },
        // }}
        className="header"
      >
        <Toolbar style={{ minHeight: '56px' }}>
          <Typography
            sx={{ marginRight: 'auto', mr: 2, }}
          >
            <Link to="/home" className="custom-link" style={{ color: '#fff' }} onClick={handleDrawerClose}>
              Reci
            </Link>
          </Typography>

          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ marginLeft: 'auto', mr: 2, display: { sm: 'none' } }}

          >
            <i class="fa fa-bars" aria-hidden="true" onClick={handleDrawerToggle}></i>
          </IconButton>

          <Box sx={{ marginRight: 'auto', display: { xs: 'none', sm: 'block' } }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>


              <Link to="/Cookbooks" className="custom-link" onClick={handleDrawerClose}>
                Cookbooks
              </Link> 



            </div>
          </Box>

          {userData ?

            <Box sx={{ marginLeft: 'auto', display: { xs: 'none', sm: 'block' } }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>

                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Image className="profile-img" src={user?.photoURL ? user?.photoURL : ProfileImage} alt="User" width="30" height="30" />
                  </IconButton>
                </Tooltip>
              </div>
            </Box>
            : ""}
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >

            <div className="text-center" style={{ padding: '0px 20px 20px 20px' }}>
              <div className="text-end" >
                <span className="badge" style={{ backgroundColor: 'red' }}>{userData?.userType ? userData?.userType : 'User'} </span>
              </div>
              <Image className="profile-img" src={user?.photoURL ? user.photoURL : ProfileImage} alt="User" width="40" height="40" />
              <Typography sx={{ fontWeight: 'bold' }}> {user?.displayName}  </Typography>
              <Typography> {user?.email} </Typography>
            </div>
            <Divider />


            {Recis?.map(({ id, data }) => (<MenuItem onClick={() => pageNavigation(`/ProfileSelected/${data.myID}`)}>
              <ListItemIcon>
                <i class="fa fa-user" aria-hidden="true"></i>
              </ListItemIcon>
              My Profile
            </MenuItem>))}


            <MenuItem onClick={() => pageNavigation('/myRecipes')}>
              <ListItemIcon>
                <Image src={RecipeIcon} alt="Recipes" style={{width:'22px'}}/>
              </ListItemIcon>
              My Recis
            </MenuItem>

            <MenuItem onClick={logout}>
              <ListItemIcon>
                <i class="fa fa-sign-out" aria-hidden="true"></i>
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
          {/* <Typography variant="h6" noWrap component="div">
            Responsive drawer
          </Typography>
           */}
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderRight: 'none' },
          }}
          className='drawer'
        >
          {drawer}
        </Drawer>
        {shouldDisplayDrawer && <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, borderRight: 'none', mt: '56px' },
          }}
          className='drawer'
          open={shouldDisplayDrawer}

        >
          {drawer}
        </Drawer>}

      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        {/* <Toolbar /> */}

      </Box>
    </Box>
  );
}

Sidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * Remove this when copying and pasting into your project.
   */
  window: PropTypes.func,
};

export default Sidebar;
