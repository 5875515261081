import logo from './ReciLogo.png';
import './App.css';
import './DashboardStyle.css';
import React, { useState, useEffect, Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import YouTube from 'react-youtube';
import GridLayout from 'react-grid-layout';
import GetRecis from './Recis'
import { Input } from 'semantic-ui-react'
// import Header from './header.js'
import Home from './Home.js'
import Header1 from './header1.js'
import {
  Router,
  Routes, // instead of "Switch"
  Route,
  Link,
  Navigate,
  BrowserRouter
} from "react-router-dom";
// import SelectedReci from "./ReciSelected"
import Login from './Login.js'
import SignUp from './SignUp.js'
import Logout from './Logout.js'
import Cookbooks from './Cookbooks.js'
import Profiles from './Profiles.js'
import ProfileSelected from './ProfileSelected.js'
import InstagramEmbed from 'react-instagram-embed';
import Kalend, { CalendarView } from 'kalend' // import component
import 'kalend/dist/styles/index.css'; // import styles
import { db } from './firebase';
import { Provider } from 'react-redux';
import store from './_store/configurestore.js';
import Dashboard from './pages/Dashboard.js';
import InstaFeeds from './InstaComponent/InstaFeeds'
import InstaVideoFeed from './InstaComponent/InstaVideoFeed'
import InstaLikeComponent from './InstaComponent/InstaLikeComponent'
import AddRecipeComponent from './Dashboard/RecipeComponent/AddRecipeComponent'
import AIRecipeComponent from './Dashboard/AIRecipeComponent'
import Category from './Category';
import CookbookSelected from './CookbookSelected';
import CalendarSelected from './CalendarSelected';
import MyHealth from './MyHealth';
import LiveStream from './LiveStream.js'
import SelectedReci from './ReciSelected.js'
import MyRecipes from './Components/MyRecipes';
import MyCookbooks from './Components/MyCookbooks';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-calendar/dist/Calendar.css';
import UserProfile from './Components/UserProfile';
import Products from './Components/Products';
import BookProducts from './Components/website/BookProducts';
import BookedEvents from './Components/admin/BookedEvents';
import PaymentHistory from './Components/admin/PaymentHistory';
import AddCookbookComponent from './Components/AddCookbookComponent';
import Checkout from './Checkout';

function App(props) {


  return (
    <Provider store={store}>
      <div className="App">

        <Header1 />

        <Routes>
          <Route path="/Login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/Logout" element={<Logout />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/feed" element={<InstaFeeds />} />
          <Route path="/videofeed" element={<InstaVideoFeed />} />
          <Route path="/like" element={<InstaLikeComponent />} />
          <Route path="/addrecipe" element={<AddRecipeComponent />} />
          <Route path="/airecipe" element={<AIRecipeComponent />} />
          <Route path="/addrecipe/:id" element={<AddRecipeComponent />} />
          <Route path="/myRecipes" element={<MyRecipes />} />
          <Route path="/myCookbooks" element={<MyCookbooks />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/:status" element={<Products />} />
          <Route path="/bookProduct" element={<BookProducts />} />
          <Route path="/userProfile" element={<UserProfile />} />
          <Route path="/bookedEvents" element={<BookedEvents />} />
          <Route path="/paymentHistory" element={<PaymentHistory />} />
          <Route path="/addcookbook" element={<AddCookbookComponent />} />
          <Route path="/checkout" element={<Checkout />} />
        </Routes>

        <ToastContainer />

      </div>
    </Provider>
  );
}

function About() {
  return (
    <>
      <main>
        <h2>Reci</h2>
        <p>
          The world's digital cookbook.
        </p>
      </main>
      <nav>
        <Link to="/Home">Home</Link>
      </nav>
    </>
  );
}


export default App;
